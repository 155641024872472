import DealDesk from 'views/pages/DealDesk';
import Dashboard from 'views/pages/dashboards/Dashboard';
import Clients from 'views/pages/Clients';
import ClientProfile from './views/pages/ClientProfile';
import Login from 'views/pages/Auth/Login';
import AcceptAndSetup from 'views/pages/Auth/AcceptAndSetup';
import Invalid from 'views/pages/Auth/Invalid';
import Profile from 'views/pages/Profile';
import EditProfile from 'views/pages/Profile/EditProfile';
import Register from 'views/pages/Auth/Register';
import VerifyEmail from 'views/pages/Auth/VerifyEmail';
import ForgotPassword from 'views/pages/Auth/ForgotPassword';
import ResetPassword from 'views/pages/Auth/ResetPassword';
import ClientMessages from 'views/pages/ClientMessages';
import KnowledgeBase from 'views/pages/KnowledgeBase';
import KnowledgeBaseArticle from 'views/pages/KnowledgeBaseArticle';
import TimelogReport from 'views/pages/TimelogReport';
import Users from 'views/pages/Users';
import CompanySettings from 'views/pages/CompanySettings';
import EditTeamMember from 'views/pages/PartnerTeam/EditTeamMember';
import AssingmentTypeCustomizations from 'views/pages/AssingmentTypeCustomizations';
import { permissions } from 'helpers/permission';
import MyKanban from 'views/pages/Kanban/MyKanban';
import GlobalKanban from 'views/pages/Kanban/GlobalKanban';
import UserKanban from 'views/pages/Kanban/UserKanban';
import ClientStudyDashboard from 'views/pages/ClientStudyDashboard';
import Studies from 'views/pages/Studies';
import CodatCallback from 'views/pages/CodatCallback';
import Partners from 'views/pages/Partners';
import PartnerTeam from 'views/pages/PartnerTeam';
import PartnerClients from 'views/pages/PartnerClients';
import Earnings from 'views/pages/Earnings';
import Contracts from 'views/pages/Contracts';
import ContractCreate from 'views/pages/ContractCreate';
import PartnerTracker from 'views/pages/PartnerTracker';
import FunnelAgreement from 'views/pages/FunnelAgreement';
import SLACallback from 'views/pages/SLACallback';
import DocumentProcessor from 'views/pages/DocumentProcessor';
import DocumentUploadPage from 'views/pages/DocumentProcessor/DocumentUploadPage';
import StudyDashboard from 'views/pages/dashboards/StudyDashboard';
import DeletedStudiesMain from 'views/pages/DeletedStudies/DeletedStudiesMain';
import TaxReturns from 'views/pages/FulfillmentStudyDashboard/TaxReturns';
import DocumentBucketContainer from 'views/pages/FulfillmentStudyDashboard/DocumentBucketContainer';
import ClientDashboard from 'views/pages/dashboards/ClientDashboard';

export const userRoutes = [
  {
    path: '/users/:id/edit',
    name: 'Profile',
    component: EditProfile,
    layout: '/admin',
    hide: true,
    title: 'Client Portal - Edit Profile',
  },
  {
    path: '/users/:id/kanban',
    name: 'Personal Kanban',
    miniName: 'PK',
    component: UserKanban,
    layout: '/admin',
    hide: true,
    title: 'Client Portal - User Kanban',
  },
  {
    path: '/users/:id',
    name: 'Profile',
    miniName: 'P',
    component: Profile,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Profile',
  },
  {
    path: '/profile',
    name: 'Profile',
    miniName: 'P',
    component: Profile,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Profile',
  },
  {
    path: '/users',
    name: 'User Management',
    component: Users,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - User Management',
  },
  {
    path: '/deleted-studies',
    name: 'Deleted Studies',
    component: DeletedStudiesMain,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Deleted Studies',
  },
];

export const partnerRoutes = [
  {
    collapse: true,
    collapseDefaultOpen: true,
    name: 'Referrals',
    icon: 'fas fa-th-large text-primary icon-gradient icon-dashboard',
    state: 'referralCollapse',
    views: [
      {
        isExact: true,
        path: '/referral/clients',
        name: 'Clients',
        miniName: '',
        component: Dashboard,
        layout: '/admin',
        title: 'Partner Portal - Referral Tracker',
      },
      {
        isExact: true,
        path: '/referral/partners',
        name: 'Partners',
        miniName: '',
        component: PartnerTracker,
        layout: '/admin',
        title: 'Partner Portal - Partner Tracker',
      },
    ],
  },
  {
    path: '/clients',
    name: 'Clients',
    component: PartnerClients,
    icon: 'fas fa-address-card text-primary icon-gradient icon-dashboard',
    layout: '/admin',
    title: 'Partner Portal - Clients',
  },
  {
    path: '/earnings',
    name: 'Earnings',
    component: Earnings,
    icon: 'fas fa-usd-circle text-primary icon-gradient icon-dashboard',
    layout: '/admin',
    title: 'Partner Portal - Earnings',
  },
  {
    path: '/team',
    name: 'Team',
    isExact: true,
    component: PartnerTeam,
    icon: 'fas fa-user-plus text-primary icon-gradient icon-dashboard',
    layout: '/admin',
    hideForRep: true,
    title: 'Partner Portal - Team',
  },
  {
    path: '/team/:id/edit',
    name: 'Edit Team Member',
    component: EditTeamMember,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Edit Team Member',
  },
  {
    path: '/studies/:id',
    isExact: true,
    name: 'Studies',
    icon: 'fas fa-cubes text-success',
    component: ClientStudyDashboard,
    layout: '/admin',
    hide: true,
    title: 'Partner Portal - View study',
  },
  {
    path: '/studies',
    name: 'Studies',
    component: ClientDashboard,
    icon: 'fas fa-home text-success icon-gradient icon-studies',
    layout: '/admin',
    title: 'Partner Portal - Studies',
  },
  {
    path: '/client/messages',
    name: 'Studies',
    icon: 'fas fa-home text-success icon-gradient icon-studies',
    layout: '/admin',
    isExact: true,
    hide: true,
    component: ClientMessages,
    title: 'Partner Portal - Messages',
  },
  ...userRoutes,
];

export const funnelRoutes = [
  // {
  //   path: '/registration',
  //   name: 'Registration',
  //   component: '',
  //   isExact: true,
  //   disabled: true,
  //   icon: 'fas fa-check-circle text-primary icon-gradient icon-dashboard',
  //   layout: '/admin',
  // },
  // {
  //   path: '/questionnaire',
  //   name: 'ERTC Questionnaire',
  //   component: '',
  //   isExact: true,
  //   disabled: true,
  //   icon: 'fas fa-clipboard text-primary icon-gradient icon-dashboard',
  //   layout: '/admin',
  // },
  {
    path: '/client/sla-agreement',
    name: 'Sign Agreement',
    isExact: true,
    component: FunnelAgreement,
    icon: 'fas fa-file-signature text-primary icon-gradient icon-dashboard',
    layout: '/admin',
    title: 'Client Portal - Sign Agreement',
  },
  // {
  //   path: '/begin-study',
  //   name: 'Begin Study',
  //   isExact: true,
  //   disabled: true,
  //   component: '',
  //   icon: 'fas fa-search text-primary icon-gradient icon-dashboard',
  //   layout: '/admin',
  // },
  // ...userRoutes,
];

export const partnerManagerRoutes = [
  {
    path: '/studies/:id/:page?',
    isExact: true,
    name: 'Studies',
    icon: 'fas fa-cubes text-success icon-gradient icon-studies',
    component: StudyDashboard,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - View Study',
  },
  {
    path: '/partners',
    name: 'Partners',
    icon: 'fas fa-gem text-success icon-gradient icon-diamond',
    component: Partners,
    layout: '/admin',
    isExact: true,
    forAdminOnly: true,
    title: 'Strike Portal - Partners',
  },
  {
    path: '/knowledge-base/:id',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBaseArticle,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Knowledge Base Article',
  },
  {
    path: '/knowledge-base',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBase,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Knowledge Base',
  },
  {
    collapse: true,
    name: 'Reporting',
    icon: 'ni ni-chart-pie-35 rotate-180 text-red ml--3 mr-3',
    state: 'reportingCollapse',
    permission: [],
    views: [
      {
        isExact: true,
        path: '/reporting/deal-desk',
        name: 'Deal Desk',
        miniName: '',
        component: DealDesk,
        layout: '/admin',
        title: 'Strike Portal - Deal Desk',
      },
    ],
  },
  ...userRoutes,
];

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    miniName: 'D',
    component: Dashboard,
    layout: '/admin',
    icon: 'fas fa-th-large text-primary icon-gradient icon-dashboard',
    title: 'Strike Portal - Dashboard',
  },
  {
    path: '/studies/:id',
    isExact: true,
    name: 'Studies',
    icon: 'fas fa-cubes text-success',
    component: ClientStudyDashboard,
    layout: '/admin',
    hide: true,
    title: 'Client Portal - View study',
  },
  {
    path: '/studies/:id/document-type/:documentBucketId/:documentId',
    isExact: true,
    name: 'Tax Returns',
    component: TaxReturns,
    layout: '/admin',
    hide: true,
    title: 'TaxReturns',
  },
  {
    path: '/studies/:id/document-type/:documentBucketId',
    isExact: true,
    name: 'Document Bucket',
    component: DocumentBucketContainer,
    layout: '/admin',
    hide: true,
    title: 'Document Bucket',
  },
  {
    path: '/studies/:id/:page?',
    isExact: true,
    name: 'Studies',
    icon: 'fas fa-cubes text-success icon-gradient icon-studies',
    component: StudyDashboard,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - View study',
  },
  {
    path: '/studies',
    name: 'Studies',
    icon: 'fas fa-home text-success icon-gradient icon-studies',
    layout: '/admin',
    isExact: true,
    title: '',
    notForPartner: true,
    component: Studies,
  },
  {
    path: '/client/messages',
    name: 'Studies',
    icon: 'fas fa-home text-success icon-gradient icon-studies',
    layout: '/admin',
    isExact: true,
    hide: true,
    component: ClientMessages,
    title: 'Client Portal - Messages',
  },
  {
    path: '/partners',
    name: 'Partners',
    icon: 'fas fa-gem text-success icon-gradient icon-diamond',
    component: Partners,
    layout: '/admin',
    isExact: true,
    forAdminOnly: true,
    title: 'Strike Portal - Partners',
  },
  {
    path: '/accounts',
    name: 'Clients',
    isExact: true,
    icon: 'fas fa-handshake text-purple',
    component: Clients,
    layout: '/admin',
    permission: permissions.LIST_CLIENTS,
    forAdminOnly: true,
    title: 'Strike Portal - Clients',
  },
  {
    path: '/codat/callback',
    name: 'Codat Callback',
    isExact: false,
    // icon: 'fas fa-handshake text-primary',
    component: CodatCallback,
    layout: '/auth',
    hide: true,
    title: 'Strike Portal - Codat',
  },
  {
    path: '/client/sla-sign',
    name: 'SLA Callback',
    isExact: false,
    // icon: 'fas fa-handshake text-primary',
    component: SLACallback,
    layout: '/auth',
    hide: true,
    title: 'Strike Portal - SLA',
  },
  {
    path: '/accounts/:clientId/:page',
    name: 'Clients',
    isExact: true,
    icon: 'fas fa-handshake text-primary',
    component: ClientProfile,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Clients Profile',
  },
  {
    collapse: true,
    name: 'Kanban',
    icon: 'ni ni-chart-bar-32 rotate-180 text-red ml--3 mr-3',
    state: 'kanbanCollapse',
    permission: [
      permissions.ACCESS_GLOBAL_KANBAN,
      permissions.ACCESS_GLOBAL_KANBAN,
    ],
    views: [
      {
        isExact: true,
        path: '/kanban/company',
        name: 'Global Kanban',
        miniName: '',
        component: GlobalKanban,
        layout: '/admin',
        permission: permissions.ACCESS_GLOBAL_KANBAN,
        title: 'Strike Portal - Global Kanban',
      },
      {
        isExact: true,
        path: '/kanban/personal',
        name: 'Personal Kanban',
        miniName: '',
        component: MyKanban,
        layout: '/admin',
        permission: permissions.ACCESS_GLOBAL_KANBAN,
        title: 'Strike Portal - Personal Kanban',
      },
    ],
    forAdminOnly: true,
  },
  {
    collapse: true,
    name: 'Reporting',
    icon: 'ni ni-chart-pie-35 rotate-180 text-red ml--3 mr-3',
    state: 'reportingCollapse',
    permission: [],
    views: [
      {
        isExact: true,
        path: '/reporting/deal-desk',
        name: 'Deal Desk',
        miniName: '',
        component: DealDesk,
        layout: '/admin',
        title: 'Strike Portal - Deal Desk',
      },
    ],
    forAdminOnly: true,
  },
  {
    // <i class="fa-sharp fa-solid fa-screwdriver-wrench"></i>
    collapse: true,
    name: 'Utility Tools',
    icon:
      'ni ni-settings rotate-180 text-success icon-gradient icon-diamond ml--3 mr-3',
    permission: [],
    views: [
      {
        isExact: true,
        path: '/utility/document-processor',
        name: 'Document Processor',
        miniName: '',
        component: DocumentProcessor,
        layout: '/admin',
        title: 'Strike Portal - Document Processor',
      },
    ],
    forAdminOnly: true,
  },
  {
    isExact: true,
    hide: true,
    path: '/utility/document-processor/upload',
    name: 'Document Processor Upload',
    miniName: '',
    component: DocumentUploadPage,
    layout: '/admin',
  },
  {
    path: '/timelog',
    name: 'Timelog',
    icon: 'fas fa-clock text-yellow',
    component: TimelogReport,
    layout: '/admin',
    permission: permissions.LIST_TIMELOGS,
    hide: true,
    title: 'Strike Portal - Timelog Report',
  },
  {
    path: '/company',
    name: 'Company Settings',
    component: CompanySettings,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Company Settings',
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: Contracts,
    layout: '/admin',
    hide: true,
    isExact: true,
    title: 'Strike Portal - Contracts',
  },
  {
    path: '/contracts/create',
    name: 'ContractsCreate',
    component: ContractCreate,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Create Contract',
  },
  {
    path: '/knowledge-base/:id',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBaseArticle,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Knowledge Base Article',
  },
  {
    path: '/knowledge-base',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBase,
    layout: '/admin',
    hide: true,
    title: 'Strike Portal - Knowledge Base',
  },
  {
    path: '/company',
    name: 'AuthFlowCompanySettings',
    component: VerifyEmail,
    layout: '/auth',
    hide: true,
    title: 'Strike Portal - Verify email',
  },
  {
    path: '/login',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Login',
  },
  {
    path: '/invitations/accept-and-Setup',
    name: 'Accept and setup',
    miniName: 'A',
    component: AcceptAndSetup,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Accept And Setup',
  },
  {
    path: '/invitations/invalid',
    name: 'Invalid',
    miniName: 'I',
    component: Invalid,
    layout: '/auth',
    redirect: true,
    hide: true,
    title: 'Strike Portal - Invalid',
  },
  {
    path: '/login/google/callback',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Login',
  },
  {
    path: '/login/microsoft/callback',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Login',
  },
  {
    path: '/register',
    name: 'Register',
    miniName: 'R',
    component: Register,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Register',
  },
  {
    path: '/email/verify',
    name: 'Set Password',
    miniName: 'SP',
    component: VerifyEmail,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Verify email',
  },
  {
    path: '/invite',
    name: 'Invite Users',
    miniName: 'SP',
    component: VerifyEmail,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Invite users',
  },
  {
    path: '/password/reset',
    name: 'Reset Password',
    miniName: 'RP',
    component: ResetPassword,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Reset password',
  },
  {
    path: '/email/reset-password',
    name: 'Reset Password',
    miniName: 'SP',
    component: ForgotPassword,
    layout: '/auth',
    redirect: true,
    title: 'Strike Portal - Forgot Password',
  },
  {
    path: '/assignment-types/:modalOpen?',
    name: 'Assignment Types',
    component: AssingmentTypeCustomizations,
    layout: '/admin',
    hide: true,
    permission: permissions.CREATE_TASK_TYPES,
    title: 'Strike Portal - Assignment Type Customizations',
  },
  ...userRoutes,
];

export default routes;
